import { useEffect, useState } from 'react';
import { useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { shopifyService } from '../services/shopifyService';
import { IAuthenticationResult } from '../components/SignIn';

export const useShopifyLogin = (): {data?: IAuthenticationResult, loading: boolean} => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IAuthenticationResult>();
    const app = window.location === window.parent.location ? null : useAppBridge();

    const login = async () => {
        if (!app) return;
        setLoading(true);
        try {
            const token = await getSessionToken(app);
            const shop = await shopifyService.getShopifyUser(token);
            const response = await shopifyService.signin(shop.email, shop.name, token);
            setData(response);
        } catch (e: any) {
            throw Error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (window.location === window.parent.location) return;
        login();
    }, []);

    return { data, loading };
};
