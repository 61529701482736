const signin = (email: string, name: string, token: string) => fetch(
    `${process.env.REACT_APP_SERVERLESS_URL}/api/signin/external`,
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            'x-rproof-source': 'shopify',
            'x-rproof-user-name': name,
            'x-rproof-user-email': email,
        },
    },
)
    .then((response) => response.json());

const getShopifyUser = (token: string) => fetch(
    `${process.env.REACT_APP_AUTH_SERVICE_URL}/api/shop`,
    {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    },
).then((response:any) => response.json());

export const shopifyService = { signin, getShopifyUser };
