import React, {
    useContext,
    useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import { Spin } from 'antd';
import Icon from '../../../components/Icon';
// utils
import { BaseDataContext } from '../../../contexts/BaseContext';
import { providedServicesService } from '../../../services';
// types
import { IIcon, IService } from '../types';
// assets
import {
    HubspotLogotype, QuickbooksLogotype, ShopifyLogotype, SlackLogotype,
} from '../Logotypes';
import SalesforceIcon from '../../../assets/images/svg/salesforce.svg';
import QuickbooksSetupGuide from '../../../assets/docs/quickbooks-setupGuide.md';
import ShopifySetupGuide from '../../../assets/docs/shopify-setupGuide.md';
import SalesforceSetupGuide from '../../../assets/docs/salesforce-setupGuide.md';
import SlackSetupGuide from '../../../assets/docs/slack-setupGuide.md';
import HubspotSetupGuide from '../../../assets/docs/hubspot-setupGuide.md';
import GithubLogotype from '../Logotypes/Github';

function SelectService({ onContinue }: { onContinue: Function }) {
    const { t } = useTranslation();
    const isMounted = useRef(true);
    const history = useHistory();
    const data = null;

    const [service, setService] = useState<IService>({
        list: [],
        info: '',
        code: '',
    });
    const [loading, setLoading] = useState<boolean>(false);
    // const [showDrawer, setShowDrawer] = useState(false);

    const { contextState } = useContext(BaseDataContext);

    const icons = useMemo(() => ({
        shopify: ShopifyLogotype,
        quickbooks: QuickbooksLogotype,
        salesforce: SalesforceIcon,
        slack: SlackLogotype,
        hubspot: HubspotLogotype,
        github: GithubLogotype,
    }), []);
    // Add icon property to provided services
    const servicesWithIcons = useMemo(() => {
        if (!Array.isArray(service.list)) return [];
        return service.list
            .filter((item) => ['Quickbooks', 'Salesforce', 'HubSpot', 'Slack', 'Shopify', 'GitHub'].indexOf(item.title) !== -1)
            .map((item) => ({
                ...item,
                // @ts-ignore
                Logo: icons[item.code],
            }));
    }, [service.list]);

    const setupGuideMap: IIcon = useMemo(() => ({
        quickbooks: QuickbooksSetupGuide,
        shopify: ShopifySetupGuide,
        salesforce: SalesforceSetupGuide,
        slack: SlackSetupGuide,
        hubspot: HubspotSetupGuide,
    }), []);

    useEffect(() => {
        if (!service.code) return;
        if (typeof onContinue === 'function') {
            onContinue(service);
        }
    }, [onContinue, service.code]);

    // Get list of available services
    useEffect(() => {
        setLoading(true);
        providedServicesService.get()
            .then((services) => {
                if (!isMounted.current) return;
                setLoading(false);
                setService((prev) => ({ ...prev, list: services }));
            })
            .catch((e: any) => {
                if (e.message === '401') {
                    history.push('/login');
                }
                console.error(e);
            });

        if (service.code && !data) {
            handleSourceChange(service.code);
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Get selected service info
    useEffect(() => {
        if (!service.code) return;
        setService((prev) => ({ ...prev, info: setupGuideMap[service.code] }));
    }, [service.code]);

    // handle change of selected service
    const handleSourceChange = (value: string) => {
        setService((prev) => ({
            ...prev,
            code: value,
        }));
    };
    const color = contextState.theme === 'light' ? '#000000' : '#FFFFFF';
    return (
        <>
            <div className="select-service-header">
                {/* <p>Create backup</p> */}
                <h5>{loading ? `${t('service_page__please_wait')}...` : t('service_page__please_select_source_type')}</h5>
            </div>
            <Spin spinning={loading} wrapperClassName="select-service-body">
                {
                    servicesWithIcons.map(({ Logo, ...item }) => (
                        <div
                          key={item.code}
                          className={`service-item ${service.code === item.code ? 'service-item-active' : ''}`}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleSourceChange(item.code)}
                          onKeyDown={() => handleSourceChange(item.code)}
                        >
                            {
                                item.code !== 'salesforce'
                                    ? <Logo color={color} />
                                    : <Icon url={Logo} width={100} />
                            }
                        </div>
                    ))
                }
            </Spin>
            <div className="select-service-coming-soon">
                <h5>{t('Coming_soon')}...</h5>
                <div className="select-service-coming-soon-body">
                    <div className="service-item">
                        <ShopifyLogotype color={color} />
                    </div>
                </div>
            </div>
            <div className="select-service-footer">
                {/* { */}
                {/*    service.code && ( */}
                {/*        <div className="setup-guide--wrapper"> */}
                {/*            <span> */}
                {/*                Any problems? Read the */}
                {/*                {' '} */}
                {/*                <Button */}
                {/*                  type="link" */}
                {/*                  className="setup-guide--btn" */}
                {/*                  onClick={() => setShowDrawer(!showDrawer)} */}
                {/*                > */}
                {/*                    setup guide */}
                {/*                </Button> */}
                {/*                {' '} */}
                {/*                for help. */}
                {/*            </span> */}
                {/*        </div> */}
                {/*    ) */}
                {/* } */}
                {/* <Button */}
                {/*  className="create-btn" */}
                {/*  onClick={handleContinue} */}
                {/*  disabled={loading || service.code === ''} */}
                {/* > */}
                {/*    Continue */}
                {/* </Button> */}
            </div>

            {/* <Drawer */}
            {/*  open={showDrawer} */}
            {/*  onClose={setShowDrawer} */}
            {/*  data={service.info} */}
            {/* /> */}
        </>
    );
}

export default SelectService;
