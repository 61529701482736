import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import Notification from '../../components/Notification';
import Wrapper from '../../components/Wrapper';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import SubscribedMessage from './SubscribedMessage';
// utils
import { commonServices, servicesService } from '../../services';
import { getCookie, parseJwt } from '../../helpers/util';
// types
import { IState } from './types';
// assets
import SuccessIcon from '../../assets/images/svg/done.svg';

function SubscribeAfterRedirect() {
    const { t } = useTranslation();
    const { email }: { email: string } = parseJwt(getCookie('token') || null) || {};
    const { pathname, search } = useLocation();
    const history = useHistory();
    const isMobile = window.innerWidth <= 480;

    const [loader, setLoader] = useState<IState>({
        show: false,
        text: '',
        icon: '',
        button: false,
    });

    useEffect(() => {
        if (pathname === '/signin/quickbooks') {
            handleSignInViaQuickbooks();
        }

        if (pathname === '/signin/hubspot') {
            handleSignInViaHubspot();
        }
        if (pathname === '/signin/salesforce') {
            handleSignInViaSalesforce();
        }

        if (pathname === '/signin/shopify') {
            handleSignInViaShopify();
        }

        if (pathname === '/signin/slack') {
            handleSignInViaSlack();
        }

        if (pathname === '/signin/github') {
            handleSignInViaGithub();
        }
    }, [pathname]);

    // ======= Quickbooks ========
    const handleSignInViaQuickbooks = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/signin/quickbooks`;

        try {
            setLoader({
                show: true,
                text: `${t('Loading')}...`,
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaQuickbooks(params.code, redirect_uri);
            const comResponse = await commonServices.getCoInfo(params, response);
            handleCheckQuickbooksCreds(response, params, comResponse);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
        }
    };

    // eslint-disable-next-line max-len
    const handleCheckQuickbooksCreds = async (response: any, params: any, coResponse: any) => {
        const userId = localStorage.getItem('userId');
        const { REACT_APP_QUICKBOOKS_CLIENT_ID, REACT_APP_QUICKBOOKS_CLIENT_SECRET } = process.env;
        // @ts-ignore
        const { realmId } = params;
        // @ts-ignore
        const { refresh_token } = response;
        // @ts-ignore
        const { CompanyInfo } = coResponse;
        const credentials = {
            client_id: REACT_APP_QUICKBOOKS_CLIENT_ID,
            client_secret: REACT_APP_QUICKBOOKS_CLIENT_SECRET,
            realm_id: realmId,
            refresh_token,
            sandbox: true,
            start_date: '1983-01-01T00:00:00Z',
            user_agent: email,
            alias: CompanyInfo.CompanyName,
        };
        if (!userId) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe('quickbooks', credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev,
                    icon: SuccessIcon,
                    text: <SubscribedMessage name="Quickbooks" />,
                    button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? t('subscribe_page__couldnt_subscribe') : errorMessage);
            }

            // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    // ======= Hubspot ========
    const handleSignInViaHubspot = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/signin/hubspot`;

        try {
            setLoader({
                show: true,
                text: `${t('Loading')}...`,
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaHubspot(params.code, redirect_uri);
            handleCheckHubspotCreds(response);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
        }
    };

    const handleCheckHubspotCreds = async (response:any) => {
        const userId = localStorage.getItem('userId');
        const { REACT_APP_HUBSPOT_CLIENT_ID, REACT_APP_HUBSPOT_CLIENT_SECRET } = process.env;
        // @ts-ignore
        const { refresh_token, hubDomain } = response;
        const credentials = {
            credentials: {
                client_id: REACT_APP_HUBSPOT_CLIENT_ID,
                client_secret: REACT_APP_HUBSPOT_CLIENT_SECRET,
                refresh_token,
                credentials_title: 'OAuth Credentials',
            },
            start_date: '2006-01-01T00:00:00Z',
            alias: hubDomain,
        };
        if (!userId) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe('hubspot', credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev,
                    icon: SuccessIcon,
                    text: <SubscribedMessage name="Hubspot" />,
                    button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? errorMessage : t('subscribe_page__couldnt_subscribe'));
            }

        // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    // ======= Shopify ========
    const handleSignInViaShopify = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const { state, shop, code } = params;

        try {
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const shopWithoutSuffix = shop.substring(0, shop.indexOf('.myshopify.com'));
            const uniqueKey = localStorage.getItem('unique') as string;
            localStorage.removeItem('unique');

            if (uniqueKey !== state) {
                throw Error(t('subscribe_page__unique_key_sent'));
            }

            const response = await commonServices.signInViaShopify(code, shopWithoutSuffix);
            handleCheckShopifyCreds(response, shopWithoutSuffix);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
        }
    };

    const handleCheckShopifyCreds = async (response:any, shop: string) => {
        const userId = localStorage.getItem('userId');
        const { REACT_APP_SHOPIFY_CLIENT_ID, REACT_APP_SHOPIFY_CLIENT_SECRET } = process.env;
        // @ts-ignore
        const { access_token } = response;
        const credentials = {
            credentials: {
                client_id: REACT_APP_SHOPIFY_CLIENT_ID,
                client_secret: REACT_APP_SHOPIFY_CLIENT_SECRET,
                access_token,
                auth_method: 'oauth2.0',
            },
            shop,
            start_date: '2004-01-01',
        };
        if (!userId) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe('shopify', credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev,
                    icon: SuccessIcon,
                    text: <SubscribedMessage name="Shopify" />,
                    button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? errorMessage : t('subscribe_page__couldnt_subscribe'));
            }

        // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    // ======= Salesforce ========
    const handleSignInViaSalesforce = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        // get saved instance name and clean
        const instance = window.localStorage.getItem('instance') as string;
        // clean saved instance name
        window.localStorage.removeItem('instance');

        try {
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaSalesforce(params.code, instance);
            handleCheckSalesforceCreds(response, instance);
        } catch (e: any) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('subscribe_page__cannot_get_token'),
                description: t(e.message),
                duration: null,
            });
        }
    };

    const handleCheckSalesforceCreds = async (response: any, instance: string) => {
        const userId = localStorage.getItem('userId');
        const { REACT_APP_SALESFORCE_CLIENT_ID, REACT_APP_SALESFORCE_CLIENT_SECRET } = process.env;
        // @ts-ignore
        const { refresh_token } = response;

        const credentials = {
            client_id: REACT_APP_SALESFORCE_CLIENT_ID,
            client_secret: REACT_APP_SALESFORCE_CLIENT_SECRET,
            is_sandbox: false,
            start_date: '1999-01-01',
            refresh_token,
            auth_type: 'Client',
            alias: instance,
        };
        if (!userId) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe('salesforce', credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev,
                    icon: SuccessIcon,
                    text: <SubscribedMessage name="Salesforce" />,
                    button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? t('subscribe_page__couldnt_subscribe') : errorMessage);
            }

            // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    // ======= Slack ========
    const handleSignInViaSlack = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/signin/slack`;

        try {
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaSlack(params.code, redirect_uri);
            if (response.status === 'failed') throw Error(response.message);
            handleCheckSlackCreds(response);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: 'Error',
                description: e.message,
                duration: null,
            });
        }
    };

    // eslint-disable-next-line max-len
    const handleCheckSlackCreds = async (response: any) => {
        const userId = localStorage.getItem('userId');
        const { REACT_APP_SLACK_CLIENT_ID, REACT_APP_SLACK_CLIENT_SECRET } = process.env;
        // @ts-ignore
        const { access_token, team, start_date } = response;
        const credentials = {
            lookback_window: 7,
            channel_filter: [],
            join_channels: true,
            credentials: {
                client_secret: REACT_APP_SLACK_CLIENT_SECRET,
                option_title: 'Default OAuth2.0 authorization',
                access_token,
                client_id: REACT_APP_SLACK_CLIENT_ID,
            },
            start_date: new Date(start_date * 1000).toISOString().replace(/.\d+Z$/g, 'Z'),
            alias: team.name,
            serviceId: team.id,
        };
        if (!userId) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe('slack', credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev,
                    icon: SuccessIcon,
                    text: <SubscribedMessage name="Slack" />,
                    button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? t('service_page__couldnt_subscribe') : errorMessage);
            }

            // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        }
    };

    // ======= Github ========
    const handleSignInViaGithub = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/signin/github`;

        try {
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaGithub(params.code, redirect_uri);
            if (response.status === 'failed') throw Error(response.message);
            handleCheckGithubCreds(response);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: 'Error',
                description: e.message,
                duration: null,
            });
        }
    };

    // eslint-disable-next-line max-len
    const handleCheckGithubCreds = async (response: any) => {
        const username = localStorage.getItem('username');
        const repoName = localStorage.getItem('repoName') as string;
        // @ts-ignore
        const { access_token } = response;
        const credentials = {
            repository: repoName,
            start_date: '2008-01-01T00:00:00Z',
            credentials: {
                access_token,
                option_title: 'OAuth Credentials',
            },
            page_size_for_large_streams: 15,
            alias: username,
        };
        if (!username || !repoName) return;

        try {
            setLoader({
                show: true,
                text: t('Creating_subscription'),
                icon: '',
                button: false,
            });

            const subscribeResponse = await servicesService.subscribe('github', credentials);

            if (subscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev,
                    icon: SuccessIcon,
                    text: <SubscribedMessage name="Github" />,
                    button: true,
                }));
            }

            if (subscribeResponse?.status !== 'success') {
                const errorMessage = subscribeResponse?.message;
                throw new Error(!errorMessage ? t('service_page__couldnt_subscribe') : errorMessage);
            }

            // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Subscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        } finally {
            localStorage.removeItem('username');
            localStorage.removeItem('repoName');
        }
    };

    return (
        <div>
            {
                loader.show
                    ? (
                        <Wrapper>
                            <div className="check-loader--container">
                                {
                                    loader.icon
                                        ? <Icon url={loader.icon} width={isMobile ? 64 : 80} />
                                        : <span />
                                }
                                <span>{ loader.text }</span>
                                {
                                    loader.button
                                        ? (
                                            <Button
                                              onClick={() => history.push('/')}
                                            >
                                                {t('Close')}
                                            </Button>
                                        )
                                        : <span />
                                }
                            </div>
                        </Wrapper>
                    )
                    : <span />
            }
        </div>
    );
}

export default SubscribeAfterRedirect;
