import React, {
    useState, useEffect, useMemo, useCallback, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Dropdown, Menu } from 'antd';
import { deleteCookie, getCookie, parseJwt } from '../../helpers/util';
import { BaseDataContext, IContextState } from '../../contexts/BaseContext';
import AccountBlock from './AccountBlock';
import LanguageSelector from '../LanguageSelector';
import Icon from '../Icon';
import ThemeSwitch from '../ThemeSwitch';
import AccountIcon from '../../assets/images/svg/account.svg';

function Account() {
    const { t } = useTranslation();
    const { contextState, setContextState } = useContext(BaseDataContext);
    const token: string | undefined = getCookie('token');

    const [state, setState] = useState(null);

    const handleSelect = useCallback(({ key }: { key: string }) => {
        if (key === 'account') return;
        if (key === 'logout') {
            deleteCookie('token');
            localStorage.removeItem('userId');
            // @ts-ignore
            setContextState((p: IContextState) => ({
                ...p,
                user: {
                    ...p.user,
                    loggedIn: false,
                },
            }));
        }
        if (key === 'privacy') {
            window.location.href = 'https://www.fatbrain.ai/privacy-policy/';
        }
        if (key === 'terms_of_service') {
            window.location.href = 'https://www.fatbrain.ai/terms/';
        }
    }, []);

    const menu = useMemo(() => (
        <Menu
          items={[
              { key: 'account', label: <AccountBlock /> },
              { key: 'lang', label: <LanguageSelector /> },
              { key: 'theme', label: <ThemeSwitch /> },
              { key: 'privacy', label: t('app_page__privacy_policy') },
              { key: 'terms_of_service', label: t('app_page__terms_of_service') },
              { key: 'logout', label: t('navbar_component__logout') },
          ]}
          onClick={handleSelect}
        />
    ), [contextState.user]);

    useEffect(() => {
        if (!token) return;
        const { given_name: username } = parseJwt(token);
        setState(username);
    }, [token]);

    return (
        <Dropdown
          overlay={menu}
          className="account-dropdown"
          overlayClassName={`${contextState.theme === 'light' ? 'light' : 'dark'} account-overlay-dropdown`}
          // eslint-disable-next-line
        >
            <Space>
                <div className="account-icon-wrapper">
                    <Icon url={AccountIcon} />
                </div>
                {state ?? t('navbar_component__account')}
            </Space>
        </Dropdown>
    );
}

export default Account;
