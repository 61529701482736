import React, { useEffect, useContext } from 'react';
// utils
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { isMobile, isTablet } from 'react-device-detect';
import { Layout } from 'antd';
import { BaseDataContext, IContextState } from './contexts/BaseContext';
// components
import Navigation from './components/Navbar';
import { PrivateRoute } from './components/PrivateRoute';
// pages
import Auth from './pages/Auth';
import Services from './pages/Home';
import Decryption from './pages/Decryption';
import RestoreFile from './pages/DataRestore/RestoreFile';
import SubscribeAfterRedirect from './pages/SubscribeAfterRedirect';
import ResubscribeAfterRedirect from './pages/ResubscribeAfterRedirect';
import Contact from './pages/Contact';
import About from './pages/About';
import Support from './pages/Support';
import AuditOfActions from './pages/AuditOfActions';
import Service from './pages/Service';
import Downloads from './pages/Downloads/Downloads';
import { AppBridgeProvider } from './components/providers/AppBridgeProvider';
// assets
import './App.scss';
// import { QueryProvider } from './components/providers/QueryProvider';

const { Content, Footer } = Layout;

function App() {
    const { i18n, t } = useTranslation();
    const currentLng: string = localStorage.getItem('lang') as string;
    // const token: string | undefined = getCookie('token');

    const { contextState, setContextState } = useContext(BaseDataContext);
    const { user } = contextState;

    useEffect(() => {
        i18n.changeLanguage(currentLng);
        const email = localStorage.getItem('email');
        // @ts-ignore
        setContextState((prev: IContextState) => ({
            ...prev,
            user: {
                ...prev.user,
                email,
            },
        }));
    }, []);

    return (
        <Router>
            <AppBridgeProvider>
                {/* <QueryProvider> */}
                <Layout
                  style={{ minHeight: '100vh' }}
                  className={currentLng === 'ru' ? 'cyrrilic-fonts' : ''}
                  data-theme={contextState.theme}
                >
                    <Navigation />
                    <Layout className="site-layout">
                        <Content style={{ padding: '0 16px' }}>
                            <Switch>
                                <PrivateRoute exact path="/" component={Services} />
                                <PrivateRoute exact path="/data-restore" component={RestoreFile} />
                                <PrivateRoute exact path="/audit_of_actions" component={AuditOfActions} />
                                <PrivateRoute exact path="/downloads" component={Downloads} />
                                <PrivateRoute exact path="/signin/:service" component={SubscribeAfterRedirect} />
                                <PrivateRoute exact path="/resubscribe/:service" component={ResubscribeAfterRedirect} />
                                <PrivateRoute exact path="/decryption" component={Decryption} />
                                <PrivateRoute exact path="/create" component={Service} />
                                <Route path="/about" component={About} />
                                <Route path="/contacts" component={Contact} />
                                <Route path="/support" component={Support} />
                                <Route path="/signup" component={Auth} />
                                <Route path="/login" component={Auth} />
                                <Route path="/forgot-password" component={Auth} />
                                <Route path="/confirm-signup" component={Auth} />
                            </Switch>
                        </Content>
                    </Layout>
                    {
                            user.loggedIn && (
                                <Footer className="site-footer">
                                    <div>
                                        <a target="_blank" rel="noreferrer" href="https://www.fatbrain.ai/terms/">{t('app_page__terms_of_service')}</a>
                                        {' '}
                                        <a target="_blank" rel="noreferrer" href="https://www.fatbrain.ai/privacy-policy/">{t('app_page__privacy_policy')}</a>
                                    </div>
                                    <p>Copyright © 2022 FatBrain LLC, All Rights Reserved.</p>
                                </Footer>
                            )
                        }
                </Layout>
                {/* </QueryProvider> */}
            </AppBridgeProvider>
        </Router>
    );
}

export default App;
