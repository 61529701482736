import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Button from '../Button';
// types
import { inputStatus } from '../../types/common';
// utils
import { BaseDataContext } from '../../contexts/BaseContext';
import { getCookie, parseJwt } from '../../helpers/util';
// assets
import './Quickbooks.scss';

interface IQuickbooksProps {
    initial?: IQuickbooksData,
    subscriptionId?: string;
    id?: string,
}

interface IQuickbooksData {
    sandbox: boolean,
    realm_id: string,
    client_id: string,
    start_date: string,
    user_agent: string,
    client_secret: string,
    refresh_token: string,
}

function Quickbooks({ id, subscriptionId, initial }: IQuickbooksProps) {
    const { t } = useTranslation();
    const { email }: { email: string } = parseJwt(getCookie('token') || null) || {};

    const [state, setState] = useState<IQuickbooksData>({
        sandbox: true,
        realm_id: '',
        client_id: '',
        start_date: '1983-01-01T00:00:00Z',
        user_agent: email,
        client_secret: '',
        refresh_token: '',
    });

    const [valid, setValid] = useState<inputStatus>();

    const { contextState } = useContext(BaseDataContext);
    const { backup } = contextState;

    useEffect(() => {
        const quickbooksService = backup.subscriptions.filter((item) => (
            item.service === 'quickbooks' && item?.serviceId === state.realm_id
        ));
        if (quickbooksService.length) {
            setValid('error');
            return;
        }
        setValid('success');
    }, [state.realm_id]);

    const handleClickSignIn = () => {
        let host = `${window.location.origin}/signin/quickbooks`;
        // change redirect url if it's resubscribe flow
        if (id && subscriptionId) {
            const quickbooksService = backup.subscriptions.filter((item) => (
                item.id === id
            ));
            if (!quickbooksService.length) return;
            host = `${window.location.origin}/resubscribe/quickbooks`;
            // @ts-ignore
            localStorage.setItem('serviceName', quickbooksService[0].serviceIdAlias);
            localStorage.setItem('serviceID', subscriptionId);
        }

        const { REACT_APP_QUICKBOOKS_CLIENT_ID } = process.env;
        window.location.replace(`https://appcenter.intuit.com/connect/oauth2?client_id=${REACT_APP_QUICKBOOKS_CLIENT_ID}&scope=com.intuit.quickbooks.accounting&redirect_uri=${host}&response_type=code&state=security_token%3D138r5719ru3e1`);
    };

    useEffect(() => {
        if (!initial) return;
        setState(initial);
    }, [initial]);

    return (
        <>
            <div className="selected-service-body">
                {
                    valid === 'error' && (
                        <p>{t('multiple_instance')} QuickBooks.</p>
                    )
                }
                <p className="definition">
                    {t('quickbooks_component__definition')}
                </p>
                <p className="definition">
                    {t('quickbooks_component__sandbox')}
                </p>
            </div>
            <div className="select-service-footer">
                <Button
                  onClick={handleClickSignIn}
                  className="create-btn connect_to__quickbooks"
                  // eslint-disable-next-line
                >
                    {t('Connect_to')} QuickBooks
                </Button>
            </div>
        </>
    );
}

export default Quickbooks;

Quickbooks.defaultProps = {
    id: undefined,
    subscriptionId: undefined,
    initial: undefined,
};
