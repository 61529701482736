import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import {
    Form,
    Input,
    Checkbox,
} from 'antd';
import { EyeInvisibleTwoTone, EyeTwoTone } from '@ant-design/icons';
import Button from '../Button';
import Notification from '../Notification';
import Icon from '../Icon';
// utils
import { authenticationService } from '../../services/authentication';
import { parseJwt, setCookie } from '../../helpers/util';
import { BaseDataContext, IContextState } from '../../contexts/BaseContext';
// Assets
import LogoIcon from '../../assets/images/logo.png';
import LogoWhiteIcon from '../../assets/images/logo_white.png';
import SmsIcon from '../../assets/images/svg/sms.svg';
import PasswordIcon from '../../assets/images/svg/password.svg';

export interface IAuthenticationResult {
    accessToken: string;
    email: string;
    givenName: string;
    roles: string | null;
    sub: string;
    type: string;
}

function SignIn() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const { contextState, setContextState } = useContext(BaseDataContext);

    const handleUnconfirmedAccount = (response: { text: string, error: object }) => {
        const { text } = response;
        Notification({
            type: 'error',
            message: t('Error_message'),
            description: <>
                <span>{ text }</span>
                <span>{t('auth_page__link_to_confirm_account')}</span>
                <Button
                  type="link"
                  size="small"
                  onClick={() => history.push('/confirm-signup')}
                  className="link-notification--btn"
                  // eslint-disable-next-line
                >
                    {t('auth_page__confirm_signup')}
                </Button>
                {/* eslint-disable-next-line */}
            </>,
            duration: null,
        });
    };

    const onFinish = async (values: any) => {
        const { email, password } = values;
        setIsLoading(true);
        try {
            // eslint-disable-next-line max-len
            const response: any = await authenticationService.signin(email, password);

            if (response?.status === 'failed') {
                if (typeof response.message === 'object' && response.message.error.name === 'UserNotConfirmedException') {
                    handleUnconfirmedAccount(response.message);
                    setIsLoading(false);
                    return;
                }
                throw Error(response.message);
            }

            const { accessToken, sub, email: _email }: IAuthenticationResult = response;
            const { exp } = parseJwt(accessToken);
            setCookie('token', accessToken, { Secure: true, 'max-age': exp, SameSite: 'None' });
            localStorage.setItem('userId', sub);
            localStorage.setItem('email', _email);
            // @ts-ignore
            setContextState((p: IContextState) => ({
                ...p,
                user: {
                    ...p.user,
                    loggedIn: true,
                    email,
                },
            }));
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error_message'),
                description: t(e.message),
                duration: 2,
            });
            setIsLoading(false);
        }
    };

    return (
        <div className="auth-modal--wrapper">
            <Icon url={contextState.theme === 'light' ? LogoWhiteIcon : LogoIcon} alt="Fatbrain Logo" width={200} />
            <div>
                <div className="login_form--container">
                    <h5>{t('Welcome')}</h5>
                    <p>{t('auth_page__signin_to_continue')}</p>
                    <Form
                      name="basic"
                      layout="vertical"
                      initialValues={{ remember: false }}
                      onFinish={onFinish}
                      autoComplete="off"
                      className="login-form"
                    >
                        <Form.Item
                          name="email"
                          rules={[{ required: true, message: t('auth_page__please_input_your_email') }]}
                          className="custom-input custom-input--text"
                        >
                            <Input prefix={<Icon url={SmsIcon} />} placeholder={t('auth_page__enter_your_email')} />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          rules={[{ required: true, message: t('auth_page__please_input_your_password') }]}
                          className="custom-input custom-input--password"
                        >
                            <Input.Password
                              prefix={<Icon url={PasswordIcon} />}
                              placeholder={t('Password')}
                              // eslint-disable-next-line
                              iconRender={(visible) => (visible ? <EyeTwoTone twoToneColor='#0EC7D9' /> : <EyeInvisibleTwoTone twoToneColor='#0EC7D9' />)}
                            />
                        </Form.Item>

                        <div className="display-inline">
                            <Form.Item name="remember" valuePropName="checked" className="form-actions">
                                <Checkbox>{t('auth_page__remember_me')}</Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                  type="link"
                                  size="small"
                                  onClick={() => history.push('/forgot-password')}
                                >
                                    {t('auth_page__forgot_password')}?
                                </Button>
                            </Form.Item>
                        </div>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                {t('auth_page__sign_in')}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <p className="switch-signup">
                    {t('auth_page__dont_have_account')}
                    <Button
                      type="link"
                      size="small"
                      onClick={() => history.push('/signup')}
                    >
                        {t('auth_page__sign_up')}
                    </Button>
                </p>
            </div>
        </div>
    );
}

export default SignIn;
