import React, { useMemo, useEffect, useContext } from 'react';
// utils
// eslint-disable-next-line import/no-unresolved
import { Location } from 'history';
import {
    BrowserView, MobileView,
} from 'react-device-detect';
import { useHistory } from 'react-router-dom';
// Components
import {
    Row,
    Col,
} from 'antd';
import { useTranslation } from 'react-i18next';
import SignIn, { IAuthenticationResult } from '../../components/SignIn';
import SignUp from '../../components/SignUp';
import ForgotPassword from '../../components/ForgotPassword';
import ConfirmSignup from '../../components/ConfirmSignup';
import Notification from '../../components/Notification';
// utils
import { BaseDataContext, IContextState } from '../../contexts/BaseContext';
import { getCookie, parseJwt, setCookie } from '../../helpers/util';
import { useShopifyLogin } from '../../hooks/useShopifyLogin';
// Assets
import './Auth.scss';

function Auth({ location }: { location: Location }) {
    const token = getCookie('token');
    const history = useHistory();
    const { t } = useTranslation();
    const { data } = useShopifyLogin();

    const { contextState, setContextState } = useContext(BaseDataContext);

    useEffect(() => {
        if (data) {
            try {
                const { accessToken, sub, email: _email }: IAuthenticationResult = data;
                const { exp } = parseJwt(accessToken);
                setCookie('token', accessToken, { Secure: true, 'max-age': exp, SameSite: 'None' });
                localStorage.setItem('userId', sub);
                localStorage.setItem('email', _email);
                // @ts-ignore
                setContextState((p: IContextState) => ({
                    ...p,
                    user: {
                        ...p.user,
                        loggedIn: true,
                        email: _email,
                    },
                }));
            } catch (e: any) {
                Notification({
                    type: 'error',
                    message: t('Error_message'),
                    description: t(e.message),
                    duration: 2,
                });
            }
        }
    }, [data]);

    useEffect(() => {
        // If user is already logged in, then redirect to backups page
        if (contextState?.user?.loggedIn && token) {
            history.push('/');
        }
    });

    const Handler = useMemo(() => {
        const { pathname } = location;

        if (pathname === '/signup') return SignUp;
        if (pathname === '/forgot-password') return ForgotPassword;
        if (pathname === '/confirm-signup') return ConfirmSignup;

        return SignIn;
    }, [location.pathname]);

    return (
        <Row>
            <BrowserView className="desktop-view--wrapper">
                <Col span={24} className="login_container">
                    <Handler />
                </Col>
            </BrowserView>

            <MobileView className="login_container--mobile">
                <Handler />
            </MobileView>
        </Row>
    );
}

export default Auth;
