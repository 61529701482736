export const authenticationService = {
    signin, signup, forgotPassword, resetPassword, resendConfirmCode, resendConfirm, signupShopify,
};

function signin(email: string, password: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/signin`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
        },
    )
        .then((response) => response.json());
}

function signup(email: string, password: string, name: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/signup`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, name }),
        },
    )
        .then((response) => response.json());
}

function signupShopify(token: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/signup/external`,
        {
            method: 'POST',
            headers: {
                Authorization: token,
                'X-rproof-source': 'shopify',
                'Content-Type': 'application/json',
            },
        },
    )
        .then((response) => response.json());
}

function forgotPassword(email: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/forgot-password`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        },
    )
        .then((response) => response.json());
}

function resetPassword(code: string, email: string, password: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/reset-forgot-password`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, email, password }),
        },
    )
        .then((response) => response.json());
}

function resendConfirmCode(email: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/resend-confirm-code`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        },
    )
        .then((response) => response.json());
}

function resendConfirm(email: string, code: string) {
    return fetch(
        `${process.env.REACT_APP_SERVERLESS_URL}/api/resend-confirm`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, code }),
        },
    )
        .then((response) => response.json());
}
