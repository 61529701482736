import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Wrapper from '../../components/Wrapper';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import { getCookie, parseJwt } from '../../helpers/util';
import { commonServices, servicesService } from '../../services';
import Notification from '../../components/Notification';
import SuccessIcon from '../../assets/images/svg/done.svg';

function ResubscribeAfterRedirect() {
    const { t } = useTranslation();
    const { email }: { email: string } = parseJwt(getCookie('token') || null) || {};
    const { pathname, search } = useLocation();
    const history = useHistory();
    const isMobile = window.innerWidth <= 480;

    const [loader, setLoader] = useState({
        show: false,
        text: '',
        icon: '',
        button: false,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (pathname === '/resubscribe/shopify') {
            handleResubscribeViaShopify();
        }
        if (pathname === '/resubscribe/quickbooks') {
            handleResubscribeViaQuickbooks();
        }
        if (pathname === '/resubscribe/slack') {
            handleResubscribeViaSlack();
        }
        if (pathname === '/resubscribe/hubspot') {
            handleResubscribeViaHubspot();
        }
        if (pathname === '/resubscribe/salesforce') {
            handleResubscribeViaSalesforce();
        }
        if (pathname === '/resubscribe/github') {
            handleResubscribeViaGithub();
        }
    }, [pathname]);

    // ======= Shopify ========
    const handleResubscribeViaShopify = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const { state, shop, code } = params;

        try {
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const uniqueKey = localStorage.getItem('unique') as string;
            localStorage.removeItem('unique');

            if (uniqueKey !== state) {
                throw Error(t('resubscribe_page__unique_key_sent'));
            }

            const response = await commonServices.signInViaShopify(code, shop);

            const { REACT_APP_SHOPIFY_CLIENT_ID, REACT_APP_SHOPIFY_CLIENT_SECRET } = process.env;
            // @ts-ignore
            const { access_token } = response;
            const credentials = {
                credentials: {
                    client_id: REACT_APP_SHOPIFY_CLIENT_ID,
                    client_secret: REACT_APP_SHOPIFY_CLIENT_SECRET,
                    access_token,
                    auth_method: 'oauth2.0',
                },
                shop,
                start_date: '2004-01-01',
            };
            handleRecheckCredentials(credentials);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Error'),
                description: t(e.message),
                duration: null,
            });
            localStorage.removeItem('serviceID');
            history.push('/');
        }
    };

    // ======= Quickbooks ========
    const handleResubscribeViaQuickbooks = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/resubscribe/quickbooks`;
        const serviceName = localStorage.getItem('serviceName');

        try {
            setLoading(true);
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaQuickbooks(params.code, redirect_uri);
            const comResponse = await commonServices.getCoInfo(params, response);

            const {
                REACT_APP_QUICKBOOKS_CLIENT_ID, REACT_APP_QUICKBOOKS_CLIENT_SECRET,
            } = process.env;
            // @ts-ignore
            const { realmId } = params;
            // @ts-ignore
            const { refresh_token } = response;
            // @ts-ignore
            const { CompanyInfo } = comResponse;
            const credentials = {
                client_id: REACT_APP_QUICKBOOKS_CLIENT_ID,
                client_secret: REACT_APP_QUICKBOOKS_CLIENT_SECRET,
                realm_id: realmId,
                refresh_token,
                sandbox: true,
                start_date: '1983-01-01T00:00:00Z',
                user_agent: email,
                alias: CompanyInfo.CompanyName,
            };
            if (credentials.alias !== serviceName) {
                throw Error(`${t('resubscribe_page__expected_different_quickbooks_company_name')}! ${t('Provided')}: ${credentials.alias}, ${t('resubscribe_page__expected')}: ${serviceName}`);
            }
            handleRecheckCredentials(credentials);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Check_failed'),
                description: t(e.message),
                duration: null,
            });
            localStorage.removeItem('serviceID');
            history.push('/');
        } finally {
            localStorage.removeItem('serviceName');
            setLoading(true);
        }
    };

    // ======= Slack ========
    const handleResubscribeViaSlack = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/resubscribe/slack`;
        const serviceName = localStorage.getItem('serviceName');

        try {
            setLoading(true);
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaSlack(params.code, redirect_uri);

            const {
                REACT_APP_SLACK_CLIENT_ID, REACT_APP_SLACK_CLIENT_SECRET,
            } = process.env;
            // @ts-ignore
            const { access_token, team, start_date } = response;
            const credentials = {
                lookback_window: 7,
                channel_filter: [],
                join_channels: true,
                credentials: {
                    client_secret: REACT_APP_SLACK_CLIENT_SECRET,
                    option_title: 'Default OAuth2.0 authorization',
                    access_token,
                    client_id: REACT_APP_SLACK_CLIENT_ID,
                },
                start_date: new Date(start_date * 1000).toISOString().replace(/.\d+Z$/g, 'Z'),
                alias: team.name,
                serviceId: team.id,
            };
            if (credentials.alias !== serviceName) {
                throw Error(`${t('resubscribe_page__expected_different_slack_company_name')} ${t('Provided')}: ${credentials.alias}, ${t('resubscribe_page__expected')}: ${serviceName}`);
            }
            handleRecheckCredentials(credentials);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Check_failed'),
                description: t(e.message),
                duration: null,
            });
            localStorage.removeItem('serviceID');
            history.push('/');
        } finally {
            localStorage.removeItem('serviceName');
            setLoading(false);
        }
    };

    // ======= Github ========
    const handleResubscribeViaGithub = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/resubscribe/github`;
        const username = localStorage.getItem('username');
        const repoName = localStorage.getItem('repoName');

        try {
            setLoading(true);
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaGithub(params.code, redirect_uri);

            // @ts-ignore
            const { access_token } = response;
            const credentials = {
                repository: repoName,
                start_date: '2008-01-01T00:00:00Z',
                credentials: {
                    access_token,
                    option_title: 'OAuth Credentials',
                },
                page_size_for_large_streams: 15,
                alias: username,
            };
            if (!username || !repoName) return;
            // if (credentials.alias !== serviceName) {
            //     throw Error(`${t('resubscribe_page__expected_different_slack_company_name')}
            //     ${t('Provided')}:
            //     ${credentials.alias}, ${t('resubscribe_page__expected')}: ${serviceName}`);
            // }
            handleRecheckCredentials(credentials);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Check_failed'),
                description: t(e.message),
                duration: null,
            });
            localStorage.removeItem('username');
            localStorage.removeItem('repoName');
            history.push('/');
        } finally {
            localStorage.removeItem('username');
            localStorage.removeItem('repoName');
            setLoading(false);
        }
    };

    // ======= Hubspot ========
    const handleResubscribeViaHubspot = async () => {
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect_uri = `${window.location.origin}/resubscribe/hubspot`;
        const serviceName = localStorage.getItem('serviceName');

        try {
            setLoading(true);
            setLoader({
                show: true,
                text: 'Loading...',
                icon: '',
                button: false,
            });
            const response = await commonServices.signInViaHubspot(params.code, redirect_uri);

            const {
                REACT_APP_HUBSPOT_CLIENT_ID, REACT_APP_HUBSPOT_CLIENT_SECRET,
            } = process.env;
            // @ts-ignore
            const { refresh_token, hubDomain } = response;

            const credentials = {
                credentials: {
                    credentials_title: 'OAuth Credentials',
                    client_id: REACT_APP_HUBSPOT_CLIENT_ID,
                    client_secret: REACT_APP_HUBSPOT_CLIENT_SECRET,
                    refresh_token,
                },
                start_date: '2006-01-01T00:00:00Z',
                alias: hubDomain,
            };
            if (hubDomain !== serviceName) {
                throw Error(`${t('resubscribe_page__expected_different_hubspot_company_name')} ${t('Provided')}: ${hubDomain}, ${t('resubscribe_page__expected')}: ${serviceName}`);
            }
            handleRecheckCredentials(credentials);
        } catch (e: any) {
            Notification({
                type: 'error',
                message: t('Check_failed'),
                description: t(e.message),
                duration: null,
            });
            localStorage.removeItem('serviceID');
            history.push('/');
        } finally {
            localStorage.removeItem('serviceName');
            setLoading(true);
        }
    };

    // ======= Salesforce ========
    const handleResubscribeViaSalesforce = async () => {
        const { REACT_APP_SALESFORCE_CLIENT_ID, REACT_APP_SALESFORCE_CLIENT_SECRET } = process.env;
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        // get saved instance name and clean
        const instance = window.localStorage.getItem('instance') as string;
        // clean saved instance name
        window.localStorage.removeItem('instance');

        try {
            setLoader({
                show: true,
                text: `${t('Loading')}...`,
                icon: '',
                button: false,
            });

            const response = await commonServices.signInViaSalesforce(params.code, instance);

            // @ts-ignore
            const { refresh_token } = response;

            const credentials = {
                client_id: REACT_APP_SALESFORCE_CLIENT_ID,
                client_secret: REACT_APP_SALESFORCE_CLIENT_SECRET,
                is_sandbox: false,
                start_date: '1999-01-01',
                refresh_token,
                auth_type: 'Client',
                alias: instance,
            };

            handleRecheckCredentials(credentials);
        } catch (e: any) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('subscribe_page__cannot_get_token'),
                description: t(e.message),
                duration: null,
            });
        }
    };

    const handleRecheckCredentials = async (credentials:any) => {
        const userId = localStorage.getItem('userId');
        const serviceId = localStorage.getItem('serviceID');

        if (!userId || !serviceId) {
            throw Error(t('resubscribe_page__required_data_not_provided'));
        }

        const resub = await servicesService.getServiceById(serviceId);
        // @ts-ignore
        const { name: serviceName, sourceId } = resub;
        const config = {
            subscriptionId: serviceId,
            sourceId,
            connectionConfiguration: credentials,
            name: serviceName,
        };

        try {
            setLoader({
                show: true,
                text: `${t('Resubscribing')}...`,
                icon: '',
                button: false,
            });

            const resubscribeResponse = await servicesService.resubscribe(config);
            if (resubscribeResponse.status === 'success') {
                setLoader((prev) => ({
                    ...prev, icon: SuccessIcon, text: `${t('Successfully_resubscribed')}!`, button: true,
                }));
            } else {
                throw new Error(t('resubscribe_page__couldnt_resubscribe'));
            }
            // @ts-ignore
        } catch (error: Error) {
            history.push('/');
            Notification({
                type: 'error',
                message: t('Resubscribe_failed'),
                description: t(error.message),
                duration: null,
            });
        } finally {
            localStorage.removeItem('serviceID');
        }
    };

    return (
        <div>
            <div className="resub-loader-wrapper">
                <Spin spinning={loading} tip={`${t('Loading')}...`} size="large" />
            </div>
            {
                loader.show
                    ? (
                        <Wrapper>
                            <div className="check-loader--container">
                                {
                                    loader.icon
                                        ? <Icon url={loader.icon} width={isMobile ? 64 : 80} />
                                        : <span />
                                }
                                <span>{ loader.text }</span>
                                {
                                    loader.button
                                        ? (
                                            <Button
                                              onClick={() => history.push('/')}
                                            >
                                                {t('Close')}
                                            </Button>
                                        )
                                        : <span />
                                }
                            </div>
                        </Wrapper>
                    )
                    : <span />
            }
        </div>
    );
}

export default ResubscribeAfterRedirect;
